
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'UpdateTime',
  props: {
    updated: Number
  },
  setup(props) {
    const timeSince = computed((): string => {
      const nowTime: number = Date.now() / 1000;
      if (!props.updated)
        return 'Never.';
      const seconds: number = Math.floor(nowTime - props.updated);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1)
        return interval + ' years ago.';
      interval = Math.floor(seconds / 2592000);
      if (interval > 1)
        return interval + ' months ago.';
      interval = Math.floor(seconds / 86400);
      if (interval > 1)
        return interval + ' days ago.';
      interval = Math.floor(seconds / 3600);
      if (interval > 1)
        return interval + ' hours ago.';
      interval = Math.floor(seconds / 60);
      if (interval > 1)
        return interval + ' mins ago.';
      /*if(Math.floor(seconds) >= 5)
          return Math.floor(seconds) + " seconds";*/
      else
        return 'RealTime.';
    });
    return {
      timeSince
    };
  }
});
